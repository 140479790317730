import {
  Container,
  ContainerContent,
  Frame,
  Stack,
} from '#app/components/layouts';
import notFoundBg from '/img/not-found-bg.jpg';
import gears from '/img/gears.svg';
import VPTLogo from '#app/assets/img/vpt-logo-white.svg';
import { HandPalm } from '@phosphor-icons/react/dist/csr/HandPalm';

function Unauthorized() {
  return (
    <Container className={`relative h-screen`}>
      <div className="absolute z-30 mt-10 flex w-full justify-center">
        <img src={VPTLogo} alt="VPT Logo" className="" />
      </div>

      <Frame className="z-10 h-screen w-full bg-primary-800 *:!justify-center">
        <img
          src={notFoundBg}
          className="absolute left-0 top-0 h-full w-full object-cover"
        />
        <ContainerContent>
          <Stack align="center" className="text-white">
            <HandPalm size={70} className="mb-4" />
            <p className="text-2xl font-medium">
              <span className="text-4xl">Unauthorized Access</span>
              <br /> You do not have permission to view this page.
              <br /> Please log in with valid credentials.
            </p>
          </Stack>
        </ContainerContent>
      </Frame>
    </Container>
  );
}

export default Unauthorized;
