import {
  type ErrorResponse,
  isRouteErrorResponse,
  useParams,
  useRouteError,
} from '@remix-run/react';
import { captureRemixErrorBoundaryError } from '@sentry/remix';
import { getErrorMessage } from '#app/utils/misc.tsx';
import Maintenance from './maintenance';
import Unauthorized from './unauthorized';

type StatusHandler = (info: {
  error: ErrorResponse;
  params: Record<string, string | undefined>;
}) => JSX.Element | null;

export function GeneralErrorBoundary({
  defaultStatusHandler = ({ error }) => (
    <p>
      {error.status} {error.data}
    </p>
  ),
  statusHandlers,
  unexpectedErrorHandler = (error) => <p>{getErrorMessage(error)}</p>,
}: {
  defaultStatusHandler?: StatusHandler;
  statusHandlers?: Record<number, StatusHandler>;
  unexpectedErrorHandler?: (error: unknown) => JSX.Element | null;
}) {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  const params = useParams();

  // Display the maintenance page for a 503 error in all environments and for any errors in production.
  if (
    (error && process.env.NODE_ENV === 'production') ||
    (isRouteErrorResponse(error) && error.status === 503)
  ) {
    return <Maintenance />;
  }

  if (isRouteErrorResponse(error) && error.status === 401) {
    return <Unauthorized />;
  }

  // Show the actual errors in non-production environments.
  if (typeof document !== 'undefined') {
    console.error(error);
  }

  return (
    <div className="container flex items-center justify-center p-20 text-h2">
      {isRouteErrorResponse(error)
        ? (statusHandlers?.[error.status] ?? defaultStatusHandler)({
            error,
            params,
          })
        : unexpectedErrorHandler(error)}
    </div>
  );
}
