import {
  Container,
  ContainerContent,
  Frame,
  Stack,
} from '#app/components/layouts';
import notFoundBg from '/img/not-found-bg.jpg';
import gears from '/img/gears.svg';
import VPTLogo from '#app/assets/img/vpt-logo-white.svg';

function Maintenance() {
  return (
    <Container className={`relative h-screen`}>
      <div className="absolute z-30 mt-10 flex w-full justify-center">
        <img src={VPTLogo} className="" />
      </div>

      <Frame className="z-10 h-screen w-full bg-primary-800 *:!justify-center">
        <img
          src={notFoundBg}
          className="absolute left-0 top-0 h-full w-full object-cover"
        />
        <ContainerContent>
          <Stack align="center" className="text-white">
            <img src={gears} className="mb-[18px]" />
            <p className="text-2xl font-medium">
              Our site is currently
              <br /> down for maintenance,
              <br /> please check back soon.
            </p>
          </Stack>
        </ContainerContent>
      </Frame>
    </Container>
  );
}

export default Maintenance;
